var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.widget.compType === "checkbox",
          expression: "widget.compType === 'checkbox'",
        },
      ],
    },
    [
      [
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_name") } },
          [
            _c("lang-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compName,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compName", $$v)
                },
                expression: "widget.compName",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_code") } },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compCode,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compCode", $$v)
                },
                expression: "widget.compCode",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_display_label") } },
          [
            _c("el-switch", {
              model: {
                value: _vm.widget.properties.showLabel,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "showLabel", $$v)
                },
                expression: "widget.properties.showLabel",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_label_width") } },
          [
            _c("el-input-number", {
              attrs: { min: 0 },
              model: {
                value: _vm.widget.properties.labelWidth,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "labelWidth", $$v)
                },
                expression: "widget.properties.labelWidth",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_label_position") } },
          [
            _c(
              "el-select",
              {
                model: {
                  value: _vm.widget.properties.labelPosition,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "labelPosition", $$v)
                  },
                  expression: "widget.properties.labelPosition",
                },
              },
              [
                _c("el-option", {
                  attrs: { label: _vm.$t("lang_align_left"), value: "left" },
                }),
                _c("el-option", {
                  attrs: { label: _vm.$t("lang_center"), value: "center" },
                }),
                _c("el-option", {
                  attrs: { label: _vm.$t("lang_align_right"), value: "right" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_label_border") } },
          [
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.widget.properties.cellBorder,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "cellBorder", $$v)
                  },
                  expression: "widget.properties.cellBorder",
                },
              },
              [
                _c("el-radio", { attrs: { label: true } }, [
                  _vm._v(_vm._s(_vm.$t("lang_display"))),
                ]),
                _c("el-radio", { attrs: { label: false } }, [
                  _vm._v(_vm._s(_vm.$t("lang_hide"))),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_minimum_quantity") } },
          [
            _c("el-input-number", {
              attrs: { min: 1 },
              model: {
                value: _vm.widget.properties.min,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "min", $$v)
                },
                expression: "widget.properties.min",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_maximum_quantity") } },
          [
            _c("el-input-number", {
              attrs: { min: 1 },
              model: {
                value: _vm.widget.properties.max,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "max", $$v)
                },
                expression: "widget.properties.max",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_column_count") } },
          [
            _c("el-input-number", {
              attrs: { min: 0 },
              model: {
                value: _vm.widget.properties.column,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "column", $$v)
                },
                expression: "widget.properties.column",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_option_style") } },
          [
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.widget.properties.optionType,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "optionType", $$v)
                  },
                  expression: "widget.properties.optionType",
                },
              },
              [
                _c("el-radio", { attrs: { label: "default" } }, [
                  _vm._v(_vm._s(_vm.$t("lang_default"))),
                ]),
                _c("el-radio", { attrs: { label: "button" } }, [
                  _vm._v(_vm._s(_vm.$t("lang_button"))),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_show_border") } },
          [
            _c("el-switch", {
              model: {
                value: _vm.widget.properties.border,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "border", $$v)
                },
                expression: "widget.properties.border",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.widget.properties.border ||
                  _vm.widget.properties.optionType === "button",
                expression:
                  "widget.properties.border || widget.properties.optionType === 'button'",
              },
            ],
            attrs: { label: "选项尺寸" },
          },
          [
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.widget.properties.size,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "size", $$v)
                  },
                  expression: "widget.properties.size",
                },
              },
              [
                _c("el-radio", { attrs: { label: "medium" } }, [
                  _vm._v(_vm._s(_vm.$t("lang_large"))),
                ]),
                _c("el-radio", { attrs: { label: "small" } }, [
                  _vm._v(_vm._s(_vm.$t("lang_in"))),
                ]),
                _c("el-radio", { attrs: { label: "mini" } }, [
                  _vm._v(_vm._s(_vm.$t("lang_small"))),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_operation_status") } },
          [
            _c(
              "el-select",
              {
                attrs: {
                  placeholder: _vm.$t("lang_please_select"),
                  clearable: "",
                },
                model: {
                  value: _vm.widget.properties.operation,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "operation", $$v)
                  },
                  expression: "widget.properties.operation",
                },
              },
              _vm._l(_vm.statusList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.label, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }